import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { ComponentProps, ReactElement, ReactNode } from "react";
import styles from "./index.module.css";

type TooltipProps = ComponentProps<typeof TooltipPrimitive.Root> &
  ComponentProps<typeof TooltipPrimitive.Content> & {
    children: ReactElement;
    content: ReactNode;
    asChild?: boolean;
    showArrow?: boolean;
    triggerClassname?: string;
    disabled?: boolean;
  };

export default function Tooltip({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  side = "top",
  align = "center",
  delayDuration,
  asChild = true,
  showArrow = false,
  triggerClassname,
  disabled,
  ...props
}: TooltipProps) {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      <TooltipPrimitive.Trigger
        disabled={disabled}
        asChild={asChild}
        className={triggerClassname}
      >
        {children}
      </TooltipPrimitive.Trigger>

      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          className={clsx(styles.tooltip, "text-[13px] font-medium")}
          side={side}
          align={align}
          sideOffset={6}
          {...props}
        >
          {content}

          {showArrow && (
            <TooltipPrimitive.Arrow
              offset={8}
              width={11}
              height={5}
              style={{
                fill: "var(--tooltipBackground)",
              }}
            />
          )}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
}
