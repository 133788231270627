import { gql } from "../../__generated__/gql";

export const GetAITool = gql(`
    query GetAiTool(
        $where: AiToolWhereUniqueInput!
    ) {
        aiTool(where: $where) {
            aiToolParticipants {
                aiTool {
                    id
                    toolId
                }
                createdAt
                owner
                id 
                permissions
                updatedAt
                user {
                    id
                    firstName
                    lastName
                    email
                    avatarUrl
                }
            }
            aiToolSteps {
                aiTool {
                    id
                }
                createdAt
                data
                id 
                stepId
                updatedAt
            }
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
        }
    }
`);

export const GetAIToolByUrlId = gql(`
    query GetAiToolByUrlId(
        $where: AiToolUrlIdInput!
    ) {
        aiToolByUrlId(where: $where) {
            aiToolParticipants {
                aiTool {
                    id
                    toolId
                }
                createdAt
                owner
                id 
                permissions
                updatedAt
                user {
                    id
                    firstName
                    lastName
                    email
                    avatarUrl
                }
            }
            aiToolSteps {
                aiTool {
                    id
                }
                createdAt
                data
                id 
                stepId
                updatedAt
            }
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
            completedAt
            deletedAt
        }
    }
`);

export const GetAiToolSteps = gql(`
    query GetAiToolSteps(
        $where: AiToolStepWhereInput!
    ) {
        aiToolSteps(where: $where) {
            aiTool {
                id
            }
            createdAt
            data
            id 
            stepId
            updatedAt
        }
    }
`);

export const GetAiToolParticipants = gql(`
    query GetAiToolParticipants(
        $where: AiToolParticipantWhereInput!
    ) {
        aiToolParticipants(where: $where) {
            aiTool {
                id
                toolId
            }
            createdAt
            owner
            id 
            permissions
            updatedAt
            user {
                id
                firstName
                lastName
                email
                avatarUrl
            }
        }
    }
`);

export const GetAiFavorites = gql(`
    query GetAiFavorites($where: AiFavoriteWhereInput!) {
        aiFavorites(where: $where) {
            id
            createdAt
            entityId
            entityType
            updatedAt
            user {
                id
            }
        }
    }
`);

export const GetAIToolsHistory = gql(`
    query GetAIToolsHistory($where: AiToolParticipantWhereInput!, $skip: Float, $take: Float, $orderBy: [AiToolParticipantOrderByInput!]) {
    aiToolParticipants(where: $where, skip: $skip, take: $take, orderBy: $orderBy) 
        {
            aiTool {
                id
                title
                toolId
                urlId
                createdAt
                updatedAt
            }
            deletedAt
            id
            updatedAt
            owner 
            permissions
            user {
                id
                firstName
                lastName
                avatarUrl
                email
            }
        }
    }
  `);

export const GetAIToolsHistoryCount = gql(`
    query GetAIToolsHistoryCount($where: AiToolParticipantWhereInput!) {
        _aiToolParticipantsMeta(where: $where) {
            count
        }
    }
`);

export const GetCurriculumToolsHistory = gql(`
    query GetCurriculumToolsHistory($where: AiToolParticipantWhereInput!, $skip: Float, $take: Float, $orderBy: [AiToolParticipantOrderByInput!]) {
    curriculumToolsParticipants(where: $where, skip: $skip, take: $take, orderBy: $orderBy) 
        {
            aiTool {
                id
                title
                toolId
                urlId
                createdAt
                updatedAt
                deletedAt
                completedAt
            }
            deletedAt
            id
            updatedAt
            owner 
            permissions
            user {
                id
                firstName
                lastName
                avatarUrl
                email
            }
        }
    }
`);

export const GetCurriculumToolsHistoryCount = gql(`
    query GetCurriculumToolsHistoryCount($where: AiToolParticipantWhereInput!) {
        _curriculumToolsParticipantsMeta(where: $where) {
            count
        }
    }
`);

export const GetResourceGeneratorToolsHistory = gql(`
    query GetResourceGeneratorToolsHistory($where: AiToolParticipantWhereInput!, $skip: Float, $take: Float, $orderBy: [AiToolParticipantOrderByInput!]) {
    resourceGeneratorParticipants(where: $where, skip: $skip, take: $take, orderBy: $orderBy) 
        {
            aiTool {
                id
                title
                toolId
                urlId
                createdAt
                updatedAt
                deletedAt
                completedAt
            }
            deletedAt
            id
            updatedAt
            owner 
            permissions
            user {
                id
                firstName
                lastName
                avatarUrl
                email
            }
        }
    }
`);

export const GetResourceGeneratorToolsHistoryCount = gql(`
    query GetResourceGeneratorToolsHistoryCount($where: AiToolParticipantWhereInput!) {
        _resourceGeneratorParticipantsMeta(where: $where) {
            count
        }
    }
`);

export const GetImageGeneratorToolsHistory = gql(`
    query GetImageGeneratorToolsHistory($where: AiToolParticipantWhereInput!, $skip: Float, $take: Float, $orderBy: [AiToolParticipantOrderByInput!]) {
    imageGeneratorParticipants(where: $where, skip: $skip, take: $take, orderBy: $orderBy) 
        {
            aiTool {
                id
                title
                toolId
                urlId
                createdAt
                updatedAt
                deletedAt
                completedAt
            }
            deletedAt
            id
            updatedAt
            owner 
            permissions
            user {
                id
                firstName
                lastName
                avatarUrl
                email
            }
        }
    }
  `);

export const GetImageGeneratorToolsHistoryCount = gql(`
    query GetImageGeneratorToolsHistoryCount($where: AiToolParticipantWhereInput!) {
        _imageGeneratorParticipantsMeta(where: $where) {
            count
        }
    }
`);
