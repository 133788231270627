import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { deleteActivity, markActivityComplete } from "../../actions/activity";
import useDropdownContext from "../../hooks/useDropdownContext";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type ActivityDropdownProps = {
  activityId: string;
  activityType: string;
};

const ActivityDropdown = ({
  activityId,
  activityType,
}: ActivityDropdownProps) => {
  const context = useDropdownContext({
    activityId,
    activityType,
  });

  const actions = [markActivityComplete, deleteActivity];

  return (
    <DropdownMenu
      asChild={true}
      triggerComponent={
        <button className="p-2 hover:bg-slate-100 rounded-full">
          <EllipsisVerticalIcon className="w-5 h-5 text-slate-600" />
        </button>
      }
    >
      <DropdownList actions={actions} context={context} />
    </DropdownMenu>
  );
};

export default ActivityDropdown;
