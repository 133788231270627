import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useStores from "../hooks/useStores";

const UnsubscribeWeeklyReport = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const { auth } = useStores();

  useEffect(() => {
    const handleUnsubscribe = async () => {
      const userId = searchParams.get("userId");
      if (!userId) {
        setStatus("error");
        return;
      }

      const success = await auth.unsubscribeFromWeeklyReports(userId);
      setStatus(success ? "success" : "error");
    };

    handleUnsubscribe();
  }, [searchParams, auth]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {status === "loading" && "Processing..."}
            {status === "success" && "Successfully Unsubscribed"}
            {status === "error" && "Error Unsubscribing"}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {status === "success" &&
              "You've been unsubscribed from weekly reports."}
            {status === "error" &&
              "There was an error processing your request."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(UnsubscribeWeeklyReport);
