import { gql } from "../../__generated__/gql";

export const LoginWithEmailId = gql(`
  mutation LoginWithEmailId($credentials: Credentials!) {
    loginWithEmail(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        username
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

export const SignUpWithEmailId = gql(`
  mutation SignUpWithEmailId($credentials: Credentials!) {
    signUpWithEmailForAI(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

// export const subscribeToNewsletter = gql(`
// mutation subscribeToNewsletter($email: String!) {
//   subscribeToNewsletter(email: $email)
// }
// `);

export const ResetPasswordWithEmailId = gql(`
  mutation ResetPasswordWithEmailId($email: String!) {
    resetPasswordWithEmail(email: $email)
  }
`);

export const CompleteOnboarding = gql(`
  mutation completeOnboarding($onboardingInput: OnboardingInput!) {
    completeOnboarding(onboardingInput: $onboardingInput)
  }
`);

export const UpdatePasswordWithEmailId = gql(`
  mutation UpdatePasswordWithEmailId($credentials: UpdatePasswordCredentials!) {
    updatePassword(credentials: $credentials)
  }
`);

export const SetNewPassword = gql(`
  mutation SetNewPassword($passwordInput: PasswordInput!) {
    setNewPassword(passwordInput: $passwordInput)
  }
`);

export const ReferAFriend = gql(`
  mutation ReferAFriend($referralInput: ReferralInput!) {
    referAFriend(referralInput: $referralInput)
  }
`);

export const VerifyEmail = gql(`
  mutation VerifyEmail($userId: String!) {
    verifyEmail(userId: $userId)
  }
`);

export const ResendVerificationEmail = gql(`
  mutation ResendVerificationEmail($userId: String!) {
    resendVerificationEmail(userId: $userId)
  }
`);

export const CreateRefreshToken = gql(/* Graphql */ `
    mutation CreateRefreshToken($tokenInput: GetTokenInput!) {
    createRefreshToken(tokenInput: $tokenInput)
  }
`);

export const HandleApiSignOut = gql(/* Graphql */ `
    mutation HandleApiSignOut($tokenInput: RemoveTokenInput!) {
      handleApiSignOut(tokenInput: $tokenInput)
  }
`);

export const UpdateUserDistrictRole = gql(/* Graphql */ `
mutation updateUserDistrictRole($updateUserDistrictRoleInput: UpdateUserDistrictRoleInput!) {
  updateUserDistrictRole(updateUserDistrictRoleInput: $updateUserDistrictRoleInput)
}
`);

export const ReferralLinkSignUp = gql(/* Graphql */ `
mutation referralLinkSignUp($referralLinkSignUpInput: ReferralLinkSignUpInput!) {
  referralLinkSignUp(referralLinkSignUpInput: $referralLinkSignUpInput)
}
`);

export const IsActivateEligible = gql(/* Graphql */ `
mutation isActivateEligible($isActiveEligibleInput: IsActivateEligibleInput!) {
  isActivateEligible(isActiveEligibleInput: $isActiveEligibleInput)
}
`);

export const ActivateUserWithPassword = gql(/* Graphql */ `
mutation activateUserWithPassword($activateUserWithPasswordInput: ActivateUserWithPasswordInput!) {
  activateUserWithPassword(activateUserWithPasswordInput: $activateUserWithPasswordInput) {
    user {
      id
      email
      firstName
      lastName
      username
      roles
      userRole
      isOnboarded
      avatarUrl
      integrations
    }
    accessToken
  }
}
`);

export const ActivateUserWithSSO = gql(/* Graphql */ `
mutation activateUserWithSSO($activateUserWithSSOInput: ActivateUserWithSSO!) {
  activateUserWithSSO(activateUserWithSSOInput: $activateUserWithSSOInput) {
    user {
      id
      email
      firstName
      lastName
      username
      roles
      userRole
      isOnboarded
      avatarUrl
      integrations
    }
    accessToken
  }
}
`);

export const FinishEnterpriseOnboarding = gql(/* Graphql */ `
    mutation FinishEnterpriseOnboarding($credentials: EnterpriseOnboardingCredentials!) {
      finishEnterpriseOnboarding(credentials: $credentials)
  }
`);

export const LoginWithEdlink = gql(`
  mutation LoginWithEdlink($credentials: EdlinkCredentials!) {
    loginWithEdlink(credentials: $credentials) {
      user {
        id
        email
        firstName
        lastName
        username
        roles
        userRole
        isOnboarded
        avatarUrl
        integrations
      }
      accessToken
    }
  }
`);

export const IsEdlinkUser = gql(`
  mutation IsEdlinkUser($email: String!) {
    isEdlinkUser(email: $email)
  }
`);

export const SubscribeToNewsletter = gql(`
    mutation subscribeToNewsletter($newsletterArgs: NewsLetterArgs!) {
      subscribeToNewsletter(newsletterArgs: $newsletterArgs) 
    }
`);

export const ExportMarkdownToGoogleDoc = gql(`
  mutation ExportMarkdownToGoogleDoc($exportMarkdownToGoogleDocsInput: ExportMarkdownToGoogleDocInput!) {
    exportMarkdownToGoogleDoc(exportMarkdownToGoogleDocsInput: $exportMarkdownToGoogleDocsInput) {
      url
      error
    }
  }
`);

export const ExportMarkdownToGoogleForm = gql(`
  mutation ExportMarkdownToGoogleForm($exportMarkdownToGoogleFormsInput: ExportMarkdownToGoogleFormInput!) {
    exportMarkdownToGoogleForm(exportMarkdownToGoogleFormsInput: $exportMarkdownToGoogleFormsInput) {
      url
      error
    }
  }
`);

export const ReferralSignupCompleted = gql(`
  mutation ReferralSignupCompleted($userId: String!, $referralCode: String!, $product: String!) {
    referralSignupCompleted(userId: $userId, referralCode: $referralCode, product: $product) 
  }
`);

export const SendReferralEmailInvites = gql(`
  mutation SendReferralEmailInvites($referralEmailInviteArgs: ReferralEmailInvitesArgs!) {
    sendReferralEmailInvites(referralEmailInviteArgs: $referralEmailInviteArgs) 
  }
`);

export const CompleteReferralAction = gql(`
  mutation CompleteReferralAction($referralCompleteActionArgs: ReferralCompleteActionArgs!) {
    completeReferralAction(referralCompleteActionArgs: $referralCompleteActionArgs) 
  }
`);

export const MarkActivityComplete = gql(`
  mutation MarkActivityComplete($id: String!, $type: String!) {
    markActivityComplete(id: $id, type: $type)
  }
`);

export const DeleteActivity = gql(`
  mutation DeleteActivity($id: String!, $type: String!) {
    deleteActivity(id: $id, type: $type)
  }
`);

export const UnsubscribeFromWeeklyReports = gql(`
  mutation UnsubscribeFromWeeklyReports($userId: String!) {
    unsubscribeFromWeeklyReports(userId: $userId)
  }
`);

export const TrackWeeklyReportClick = gql(`
  mutation TrackWeeklyReportClick($userId: String!, $destination: String!) {
    trackWeeklyReportClick(userId: $userId, destination: $destination)
  }
`);
