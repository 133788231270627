import { gql } from "../../__generated__/gql";

export const NewBotConversation = gql(`
  mutation NewBotConversation($data: NewBotConversationInput!) {
    newBotConversation(data: $data) {
      createdAt
      data
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const NewBotConversationEntry = gql(`
  mutation NewBotConversationEntry($data: NewConversationEntryInput!) {
    newBotConversationEntry(data: $data) {
      conversation {
        id
      }
      createdAt
      deletedAt
      entry
      id
      speaker
      updatedAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`);

export const RegenerateBotConversationEntry = gql(`
  mutation RegenerateBotConversationEntry($data: RegenerateConversationEntryInput!) {
    regenerateBotConversationEntry(data: $data) {
      conversation {
        id
      }
      createdAt
      deletedAt
      entry
      id
      speaker
      updatedAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`);

export const DeleteBotConversation = gql(`
  mutation DeleteBotConversation($where: BotConversationWhereUniqueInput!) {
    markBotConversationDeleted(where: $where) {
      id
      urlId
      deletedAt
    }
  }
`);

export const RestoreBotConversation = gql(`
  mutation RestoreBotConversation($where: BotConversationWhereUniqueInput!) {
    restoreBotConversation(where: $where) {
      id
      urlId
      deletedAt
    }
  }
`);

export const MarkBotConversationComplete = gql(`
  mutation MarkBotConversationComplete($where: BotConversationWhereUniqueInput!) {
    markBotConversationComplete(where: $where) {
      id
      urlId
      completedAt
    }
  }
`);

export const UnmarkBotConversationComplete = gql(`
  mutation UnmarkBotConversationComplete($where: BotConversationWhereUniqueInput!) {
    unmarkBotConversationComplete(where: $where) {
      id
      urlId
      completedAt
    }
  }
`);

export const RenameBotConversation = gql(`
  mutation RenameBotConversation($where: BotConversationWhereUniqueInput!, $data: BotConversationUpdateInput!) {
    updateBotConversation(where: $where, data: $data) {
      id
      urlId
      title
    }
  }
`);
