import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import {
  action,
  makeObservable,
  observable,
  override,
  runInAction,
} from "mobx";
import { NewBotConversationInput } from "../__generated__/graphql";
import {
  DeleteBotConversation,
  MarkBotConversationComplete,
  NewBotConversation,
  RenameBotConversation,
  RestoreBotConversation,
  UnmarkBotConversationComplete,
} from "../graphql/botConversation/botConversation.mutations";
import {
  GetBotConversationByUrlId,
  GetBotConversationHistory,
  GetBotConversationHistoryCount,
  GetChatByUrlId,
} from "../graphql/botConversation/botConversation.queries";
import BotConversation from "../models/BotConversation";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BotConversationStore extends BaseStore<BotConversation> {
  isLoadingConversationsHistory = false;
  error: string | null = null;
  skip: number = 0;
  take: number = 20;
  totalCount: number = 10;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BotConversation, apolloClient);

    makeObservable(this, {
      isLoadingConversationsHistory: observable,
      error: observable,
      skip: observable,
      take: observable,
      totalCount: observable,
      // Computed
      sortedData: override,
      // Actions
      fetchConversationAndChatByUrlId: action,
      fetchByUrlId: action,
      createNewConversation: action,
      resetPagination: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  get sortedData(): BotConversation[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  fetchConversationsHistory = async (): Promise<boolean> => {
    this.isLoadingConversationsHistory = true;
    this.error = null;

    try {
      const res = await this.apolloClient.query({
        query: GetBotConversationHistory,
        variables: {
          skip: this.skip,
          take: this.take,
        },
      });

      const { getBotConversationHistory: conversations } = res.data;

      if (!conversations || conversations.length === 0) {
        return false;
      }

      // Convert raw data to BotConversation instances
      conversations.map((conv: any) => this.add(conv));

      this.skip += this.take;
      return true;
    } catch (error: any) {
      this.error = error.message;
      return false;
    } finally {
      this.isLoadingConversationsHistory = false;
    }
  };

  async fetchTotalCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetBotConversationHistoryCount,
    });

    if (error) {
      this.error = error.message;
    }

    this.totalCount = data.getBotConversationHistoryCount.count;
  }

  updateTotalCount(count: number) {
    this.totalCount = count;
  }

  fetchConversationAndChatByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetChatByUrlId,
          variables: {
            chatId: urlId,
          },
        })
        .then((res) => {
          const conversation = res.data.getChatByUrlId;
          resolve(conversation);
        })
        .catch(() => {
          reject(null);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });
    });
  };

  fetchByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBotConversationByUrlId,
          variables: {
            where: {
              urlId,
            },
          },
        })
        .then((res) => {
          const conversation = res.data.botConversationByUrlId;

          invariant(conversation, "Conversation not found");

          const sanitizeBotConversation = {
            completedAt: conversation.completedAt,
            createdAt: conversation.createdAt,
            data: conversation.data,
            deletedAt: conversation.deletedAt,
            id: conversation.id,
            title: conversation.title,
            updatedAt: conversation.updatedAt,
            urlId: conversation.urlId,
          };

          // Fetch the entries for this conversation
          this.rootStore.conversationEntries.fetchBotConversationEntries(
            conversation.id
          );

          this.rootStore.conversationParticipants.fetchConversationParticipants(
            conversation.id
          );

          this.rootStore.aiFeedback.fetchAiFeedbackForParentEntityId(
            conversation.id
          );

          this.add(sanitizeBotConversation);

          resolve(true);
        })
        .catch(() => {
          reject(false);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });
    });
  };

  async createNewConversation(
    data: NewBotConversationInput
  ): Promise<BotConversation> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewBotConversation,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newBotConversation) {
        throw new Error("No data returned from newBotConversation mutation");
      }

      const convo = res.data.newBotConversation;

      const sanitizedBotConversation = {
        createdAt: convo.createdAt,
        data: convo.data,
        id: convo.id,
        title: convo.title,
        urlId: convo.urlId,
        updatedAt: convo.updatedAt,
      };

      return this.add(sanitizedBotConversation);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Helpers
  getByUrlParam = (urlId: string): BotConversation | undefined => {
    return this.sortedData.find((conversation) =>
      urlId.endsWith(conversation.urlId)
    );
  };

  resetPagination() {
    this.skip = 0;
    this.error = null;
    this.totalCount = 10;
  }

  deleteConversationWithoutRemovingFromStore = async (id: string) => {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DeleteBotConversation,
        variables: {
          where: { id },
        },
      });

      if (!res.data || !res.data.markBotConversationDeleted) {
        throw new Error("No data returned from deleteBotConversation mutation");
      }

      this.add(res.data.markBotConversationDeleted);
    } catch (error: any) {
      throw error;
    }
  };

  deleteConversation = async (id: string) => {
    try {
      await this.apolloClient.mutate({
        mutation: DeleteBotConversation,
        variables: {
          where: { id },
        },
      });

      // Remove from local store
      this.data.delete(id);

      // Update total count
      this.totalCount--;

      // If we've deleted the last item on the current "page"
      if (this.sortedData.length === 0 && this.skip > 0) {
        // Move back one page
        this.skip = Math.max(0, this.skip - this.take);
        // Fetch more data
        await this.fetchConversationsHistory();
      }
    } catch (error: any) {
      this.error = error.message;
    }
  };

  restoreConversation = async (id: string) => {
    try {
      const res = await this.apolloClient.mutate({
        mutation: RestoreBotConversation,
        variables: {
          where: { id },
        },
      });

      if (!res.data || !res.data.restoreBotConversation) {
        throw new Error(
          "No data returned from restoreBotConversation mutation"
        );
      }

      this.add(res.data.restoreBotConversation);
    } catch (error: any) {
      this.error = error.message;
    }
  };

  markConversationComplete = async (id: string) => {
    try {
      const response = await this.apolloClient.mutate({
        mutation: MarkBotConversationComplete,
        variables: {
          where: { id },
        },
      });

      console.log("Response", response);

      // Update local store
      const conversation = this.data.get(id);
      if (conversation) {
        conversation.completedAt = new Date();
      }
    } catch (error: any) {
      this.error = error.message;
    }
  };

  unmarkConversationComplete = async (id: string) => {
    try {
      await this.apolloClient.mutate({
        mutation: UnmarkBotConversationComplete,
        variables: {
          where: { id },
        },
      });

      // Update local store
      const conversation = this.data.get(id);
      if (conversation) {
        conversation.completedAt = null;
      }
    } catch (error: any) {
      this.error = error.message;
    }
  };

  renameConversation = async (id: string, title: string) => {
    try {
      const response = await this.apolloClient.mutate({
        mutation: RenameBotConversation,
        variables: {
          where: { id },
          data: {
            title,
          },
        },
      });

      if (!response.data || !response.data.updateBotConversation) {
        throw new Error("No data returned from renameBotConversation mutation");
      }

      this.add(response.data.updateBotConversation);
    } catch (error: any) {
      throw error;
    }
  };
}
