import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { createDropdownAction } from ".";
import { Trash01Icon } from "../icon-imports";

export const markActivityComplete = createDropdownAction({
  type: "button",
  text: "Mark as Complete",
  trackingName: "dropdown-selected-mark-activity-complete",
  icon: <CheckCircleIcon className="w-4 h-4" />,
  show: () => true,
  onSelect: async ({ stores, activityId, activityType, toastsContext }) => {
    const { auth } = stores;
    if (!activityId || !activityType) return;
    const success = await auth.markActivityComplete(activityId, activityType);

    if (success) {
      toastsContext.addToast("Resource marked as complete", {
        type: "success",
      });
    } else {
      toastsContext.addToast("Failed to mark resource as complete", {
        type: "error",
      });
    }
  },
});

export const deleteActivity = createDropdownAction({
  type: "button",
  text: "Delete",
  trackingName: "dropdown-selected-delete-activity",
  icon: <Trash01Icon className="w-4 h-4" />,
  destructive: true,
  show: () => true,
  onSelect: async ({ stores, activityId, activityType, toastsContext }) => {
    const { auth } = stores;
    if (!activityId || !activityType) return;
    const success = await auth.deleteActivity(activityId, activityType);

    if (success) {
      toastsContext.addToast("Resource deleted", {
        type: "success",
      });
    } else {
      toastsContext.addToast("Failed to delete resource", {
        type: "error",
      });
    }
  },
});
