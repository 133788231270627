import { action, computed, makeObservable, observable } from "mobx";
import {
  District,
  Drive,
  EnumDriveDriveType,
  EnumInviteStatus,
  EnumUserDistrictRoles,
  EnumUserLanguage,
  Invite,
} from "../__generated__/graphql";
import AiToolParticipant from "./AiToolParticipant";
import BotConversationParticipant from "./BotConversationParticipant";
import DeletableModel from "./DeletableModel";
import Attribute from "./decorators/Attribute";

export type IntegrationProperties = {
  [key: string]: string;
};

export enum IntegrationProvider {
  GOOGLE_CALENDAR = "google-calendar",
  GOOGLE_DRIVE = "google-drive",
  ONE_DRIVE = "one-drive",
  GOOGLE_CLASSROOM = "google-classroom",
  CANVAS = "canvas",
  MOODLE = "moodle",
  SCHOOLOGY = "schoology",
  BLACKBAUD = "blackbaud",
}

export enum PricingPlanTier {
  Basic = "basic",
  Pro = "pro",
  Enterprise = "enterprise",
}

const calculateDaysLeft = (startDate: string) => {
  const freeTrialDuration = Number(
    import.meta.env.VITE_APP_PRO_PLAN_TRIAL_DURATION || "14"
  );

  const onboardedDate = new Date(startDate);
  const trialEnd = new Date(onboardedDate.getTime());
  trialEnd.setDate(trialEnd.getDate() + freeTrialDuration);
  const today = new Date();
  const remaining = Math.ceil(
    (trialEnd.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
  const remainingDays = remaining > 0 ? remaining : 0;
  return remainingDays;
};

const calculateDaysRemaining = (trialEnd: string) => {
  const today = new Date();
  const remaining = Math.ceil(
    (new Date(trialEnd).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );
  return Math.max(remaining, 0);
};

class User extends DeletableModel {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
  isOnboarded: boolean | null;
  userRole: string | null;
  onboardedAt: string | null;
  verifiedAt: string | null;
  freeTrialStart: string | null;
  freeTrialEnd: string | null;
  copilotFreeTrialEnd: string | null;
  districtRoles: EnumUserDistrictRoles[] | null;
  district: District | null;
  authProvider: string;
  integrations: { [key in IntegrationProvider]?: IntegrationProperties } | null;
  aiToolParticipants: AiToolParticipant[] | null;
  botConversationParticipants: BotConversationParticipant[] | null;
  invites: Invite[] | null;
  enterpriseUser: boolean;
  invitedCollaborators: boolean;
  teamMemberCount: number;
  language: EnumUserLanguage | null;
  subscriptionInterval?: "Monthly" | "Annually" | null;
  subscriptionType?: "Copilot" | "Slides" | "CopilotSlidesBundle" | null;
  enterprisePlan?: {
    isEnterpriseAccount: boolean;
    isLicenseActive: boolean;
    subscriptionType: string;
  } | null;
  referralActionsPending?: {
    useChat: boolean;
    finishOnboarding: boolean;
    createSlideshow: boolean;
  } | null;
  processLinksMap: {
    [key: string]: {
      id: string;
      assetId: string;
      logs: string[];
      status: string;
      error?: string | null;
      aiFlaggedContent?: boolean | null;
      finalOutput?: string | null;
    };
  } = {};
  differentiatedResourcesInitModelMap: {
    [key: string]: {
      id: string;
      processedCount: number;
      totalCount: number;
      logs: string[];
      status: string;
      error?: string | null;
      aiFlaggedContent?: boolean | null;
    };
  } = {};

  constructor(fields: Record<string, any>, store: any) {
    super(fields, store);
    makeObservable(this, {
      email: observable,
      username: observable,
      firstName: observable,
      lastName: observable,
      avatarUrl: observable,
      isOnboarded: observable,
      userRole: observable,
      onboardedAt: observable,
      verifiedAt: observable,
      freeTrialStart: observable,
      freeTrialEnd: observable,
      copilotFreeTrialEnd: observable,
      districtRoles: observable,
      district: observable,
      authProvider: observable,
      integrations: observable,
      aiToolParticipants: observable,
      botConversationParticipants: observable,
      invites: observable,
      enterpriseUser: observable,
      invitedCollaborators: observable,
      teamMemberCount: observable,
      language: observable,
      subscriptionInterval: observable,
      subscriptionType: observable,
      enterprisePlan: observable,
      referralActionsPending: observable,
      processLinksMap: observable,
      differentiatedResourcesInitModelMap: observable,

      //  Computed Properties
      fullName: computed,
      initial: computed,
      isSubscribedToPlan: computed,
      currentPlan: computed,
      doesWorkspaceHavePaidSeats: computed,
      isWorkspaceOwner: computed,
      canManageWorkspace: computed,
      numberOfReferrals: computed,
      defaultDriveId: computed,
      freeTrialDaysLeft: computed,
      isProWithoutFreeTrialAndEnterprise: computed,
      isProWithoutFreeTrial: computed,

      // Actions
      setProcessLinkStatus: action,
      removeProcessLinkStatus: action,
      setDifferentiatedResourcesToolInit: action,
      removeDifferentiatedResourcesToolInit: action,
    });

    Attribute(this, "email");
    Attribute(this, "username");
    Attribute(this, "firstName");
    Attribute(this, "lastName");
    Attribute(this, "avatarUrl");
    Attribute(this, "isOnboarded");
    Attribute(this, "userRole");
    Attribute(this, "onboardedAt");
    Attribute(this, "verifiedAt");
    Attribute(this, "freeTrialStart");
    Attribute(this, "freeTrialEnd");
    Attribute(this, "copilotFreeTrialEnd");
    Attribute(this, "districtRoles");
    Attribute(this, "district");
    Attribute(this, "authProvider");
    Attribute(this, "integrations");
    Attribute(this, "language");
    Attribute(this, "subscriptionInterval");
    Attribute(this, "subscriptionType");

    this.updateFromJson(fields);
  }

  get fullName(): string {
    return this.firstName + " " + this.lastName;
  }

  get initial(): string {
    return this.firstName[0];
  }

  // Excludes check for free trial
  get isSubscribedToPlan() {
    // LEGACY PRO PLAN EXCEPTIONS (KEEP FOR BACKWARD COMPATIBILITY for Now)
    const proPlanExceptions = JSON.parse(
      import.meta.env.VITE_APP_PRO_PLAN_EXCEPTIONS || "[]"
    );

    if (proPlanExceptions.includes(this.email)) {
      return true;
    }

    // LEGACY SUBSCRIPTION CHECK for PRO PLAN (KEEP FOR BACKWARD COMPATIBILITY for Now)
    if (
      this.district &&
      this.district.payments &&
      !this.district.payments.enterprisePlan
    ) {
      const status = this.district.payments.status;
      const activeUsers = this.district.payments.activeUsers;

      if (status === "paid" && activeUsers && activeUsers.includes(this.id)) {
        return true;
      }
    }

    // V2
    // First we check the enterprise subscription
    if (
      this.enterprisePlan &&
      this.enterprisePlan.isLicenseActive &&
      (this.enterprisePlan.subscriptionType === "Copilot" ||
        this.enterprisePlan.subscriptionType === "CopilotSlidesBundle")
    ) {
      return true;
    }

    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return true;
    }

    return false;
  }

  // Includes check for free trial
  get currentPlan(): PricingPlanTier {
    const proPlanExceptions = JSON.parse(
      import.meta.env.VITE_APP_PRO_PLAN_EXCEPTIONS || "[]"
    );

    // LEGACY PRO PLAN EXCEPTIONS (KEEP FOR BACKWARD COMPATIBILITY for Now)
    if (proPlanExceptions.includes(this.email)) {
      return PricingPlanTier.Pro;
    }

    // Enterprise Subscription Check
    if (
      this.district &&
      this.district.payments &&
      !this.district.payments.enterprisePlan
    ) {
      const status = (this.district.payments as any).status;
      const activeUsers = (this.district.payments as any).activeUsers;

      if (status === "paid" && activeUsers && activeUsers.includes(this.id)) {
        return PricingPlanTier.Pro;
      }
    }

    // Free Trial Check
    if (
      !this.freeTrialEnd &&
      this.freeTrialStart &&
      calculateDaysLeft(this.freeTrialStart) > 0
    ) {
      return PricingPlanTier.Pro;
    }

    // Referral Free Trial Check (copilotFreeTrialEnd - current date)
    if (
      this.copilotFreeTrialEnd &&
      calculateDaysRemaining(this.copilotFreeTrialEnd) > 0
    ) {
      return PricingPlanTier.Pro;
    }

    // V2
    // First we check the enterprise subscription
    if (
      this.enterprisePlan &&
      this.enterprisePlan.isLicenseActive &&
      (this.enterprisePlan.subscriptionType === "Copilot" ||
        this.enterprisePlan.subscriptionType === "CopilotSlidesBundle")
    ) {
      return PricingPlanTier.Enterprise;
    }

    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return PricingPlanTier.Pro;
    }

    return PricingPlanTier.Basic;
  }

  get doesWorkspaceHavePaidSeats() {
    let paid = false;
    if (this.district && this.district.payments) {
      const status = this.district.payments.status;
      const activeUsers = this.district.payments.activeUsers;
      if (status === "paid" && activeUsers && activeUsers.length > 0) {
        paid = true;
      }
    }
    return paid;
  }

  get isProWithoutFreeTrialAndEnterprise(): boolean {
    // We check if user is pro by only individual subscription
    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return true;
    }

    return false;
  }

  get isProWithoutFreeTrial(): boolean {
    // We check if user is pro by both individual subscription and enterprise subscription

    // First we check the enterprise subscription
    if (
      this.enterprisePlan &&
      this.enterprisePlan.isLicenseActive &&
      (this.enterprisePlan.subscriptionType === "Copilot" ||
        this.enterprisePlan.subscriptionType === "CopilotSlidesBundle")
    ) {
      return true;
    }

    // Then we check the individual subscription
    if (
      this.subscriptionType &&
      (this.subscriptionType === "CopilotSlidesBundle" ||
        this.subscriptionType === "Copilot")
    ) {
      return true;
    }

    return false;
  }

  get isWorkspaceOwner() {
    let isOwner = false;
    if (
      this.district &&
      this.district.createdBy &&
      this.district.createdBy.id === this.id
    ) {
      isOwner = true;
    }
    return isOwner;
  }

  get canManageWorkspace() {
    let isOwner = false;
    if (
      this.district &&
      this.district.createdBy &&
      this.district.createdBy.id === this.id
    ) {
      isOwner = true;
    } else if (
      this.districtRoles &&
      (this.districtRoles.includes(EnumUserDistrictRoles.Administrator) ||
        this.districtRoles.includes(
          EnumUserDistrictRoles.DistrictAdministrator
        ))
    ) {
      isOwner = true;
    }
    return isOwner;
  }

  // Calculate the number of referrals after the cut off date
  get numberOfReferrals() {
    let acceptedInvites = 0;
    const cutOffDate = new Date("2023-11-14");
    this.invites?.forEach((invite) => {
      if (
        invite.status === EnumInviteStatus.Accepted &&
        new Date(invite.createdAt) > cutOffDate
      ) {
        acceptedInvites += 1;
      }
    });
    return acceptedInvites;
  }

  get defaultDriveId(): string | null {
    const { drives } = this.store.rootStore;

    const defaultDrive = drives.sortedData.find((drive: Drive) => {
      return drive.driveType === EnumDriveDriveType.MyDrive;
    });

    console.log("defaultDrive", defaultDrive);

    if (defaultDrive) {
      return defaultDrive.id;
    }

    return null;
  }

  get freeTrialDaysLeft() {
    if (this.copilotFreeTrialEnd) {
      return calculateDaysRemaining(this.copilotFreeTrialEnd);
    }

    if (!this.freeTrialStart || this.freeTrialEnd) return 0;

    return calculateDaysLeft(this.freeTrialStart);
  }

  setProcessLinkStatus(
    id: string,
    assetId: string,
    logs: string[],
    status: string,
    error?: string | null,
    aiFlaggedContent?: boolean | null,
    finalOutput?: string | null
  ) {
    let updateProcessLinksMap = {
      ...this.processLinksMap,
    };

    if (updateProcessLinksMap[id]) {
      updateProcessLinksMap[id] = {
        ...updateProcessLinksMap[id],
        assetId,
        logs,
        status,
        error,
        aiFlaggedContent,
        finalOutput,
      };
    } else {
      updateProcessLinksMap[id] = {
        id,
        assetId,
        logs,
        status,
        error,
        aiFlaggedContent,
        finalOutput,
      };
    }

    this.processLinksMap = updateProcessLinksMap;
  }

  removeProcessLinkStatus(id: string) {
    let updateProcessLinksMap = {
      ...this.processLinksMap,
    };

    if (updateProcessLinksMap[id]) {
      delete updateProcessLinksMap[id];
    }

    this.processLinksMap = updateProcessLinksMap;
  }

  // We are storing differentiated resource generator init status model
  setDifferentiatedResourcesToolInit(
    id: string,
    processedCount: number,
    totalCount: number,
    logs: string[],
    status: string,
    error?: string | null,
    aiFlaggedContent?: boolean | null
  ) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      updateDifferentiatedResourceMap[id] = {
        ...updateDifferentiatedResourceMap[id],
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    } else {
      updateDifferentiatedResourceMap[id] = {
        id,
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }

  removeDifferentiatedResourcesToolInit(id: string) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      delete updateDifferentiatedResourceMap[id];
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }
}

export default User;
