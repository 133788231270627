import {
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import mixpanel from "mixpanel-browser";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  EnumDriveDriveType,
  RecentActivityItem,
} from "../__generated__/graphql";
import ButtonLarge from "../components/ButtonLarge";
import AccountDropdown from "../components/Dropdowns/AccountDropdown";
import ActivityDropdown from "../components/Dropdowns/ActivityDropdown";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  XIcon,
} from "../components/Sidebar/AppSidebar/SocialIcons";
import Tooltip from "../components/Tooltip";
import useStores from "../hooks/useStores";
import {
  AppsaddIcon,
  Book01Icon,
  ChatIcon,
  File03Icon,
  ImageIcon,
  MonitorIcon,
} from "../icon-imports";
import { PricingPlanTier } from "../models/User";
import {
  activeToolRoute,
  conversationRoute,
  driveRoute,
  editResourceRoute,
  imageGeneratorOutputRoute,
  instantResourcesOutputRoute,
} from "../utils/routeHelper";

const tools = [
  {
    title: "Chat with me",
    description:
      "Your AI teaching assistant for lesson planning, parent communication, IEPs, and daily tasks.",
    path: "/chat",
    color: "sky",
    logo: "/imgs/logos/chat-with-me.png",
  },
  {
    title: "Differentiate It",
    description:
      "Create leveled teaching resources instantly, customized by grade level and language proficiency.",
    path: "/instant-resources",
    color: "green",
    logo: "/imgs/logos/differentiate-it.png",
  },
  {
    title: "Design Curriculum",
    description:
      "Build standards-aligned lesson plans, assessments, and activities with AI-powered guidance.",
    path: "/tools",
    color: "red",
    logo: "/imgs/logos/design-curriculum.png",
  },
  {
    title: "Create Images",
    description:
      "Generate custom educational visuals and artwork to enhance student engagement and learning.",
    path: "/image-generator",
    color: "yellow",
    logo: "/imgs/logos/create-images.png",
  },
];

// const otherTools = [
//   {
//     title: "Alayna AI for Google Slides",
//     description: "Craft stunning presentations using our extension",
//     path: "/slides",
//     color: "orange",
//     logo: "/imgs/logos/slides-generator-no-text-new.png",
//     featureImg: "/imgs/other-tools/slides.png",
//   },
//   {
//     title: "MasteryMate",
//     description: "Create Project-Based Learning (PBL) experiences with AI",
//     externalLink: "https://masterymate.ai",
//     color: "purple",
//     logo: "/imgs/logos/masterymate-no-text.png",
//     featureImg: "/imgs/other-tools/masterymate.png",
//   },
//   {
//     title: "GradingPal",
//     description: "Automatically grade and provide personalized feedback",
//     externalLink: "https://gradingpal.ai",
//     color: "blue",
//     logo: "/imgs/logos/gradingpal-no-text.png",
//     featureImg: "/imgs/other-tools/gradingpal.png",
//   },
// ];

const resources = [
  {
    title: "PD Deck",
    previewImage: "https://i.ibb.co/YTWqPR7d/pd-deck-cover.png",
    description:
      "Use this to explain how Alayna AI & it's various features to your staff.",
    action: "View Deck",
    path: "https://www.canva.com/design/DAF5XKzIa_o/PLPm0juXs0bBBT_LQCBSSQ/edit?utm_content=DAF5XKzIa_o&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    external: true,
  },
  {
    title: "Free AI Training Course",
    previewImage: "https://i.ibb.co/7JjJ1hKh/free-course-cover.png",
    description: "View our free AI Training Course loved by educators.",
    action: "View Course",
    path: "https://alayna.ai/ai-course-for-teachers",
    external: true,
  },
  {
    title: "Early school partner program",
    previewImage: "https://i.ibb.co/LDdNNG2d/district-parternship-cover.png",
    description:
      "Get discounts on all 4 Alayna AI products and other benefits.",
    action: "Get a Quote",
    path: "https://alayna.ai/for-schools",
    external: true,
  },
];

// Add this helper function
const getActivityUrl = (type: string, url: string) => {
  switch (type) {
    case "Chat":
      return { path: conversationRoute(url) };
    case "InstantResources":
      return { path: instantResourcesOutputRoute(url) };
    case "CurriculumCreator":
      return { path: activeToolRoute(url) };
    case "ImageGenerator":
      return { path: imageGeneratorOutputRoute(url) };
    case "Slides":
      return { path: url, external: true };
    case "Documents":
      return { path: editResourceRoute(url) };
    default:
      return { path: url };
  }
};

// Company Tools Banner Component
const CompanyToolsBanner = () => {
  type Tool = {
    name: string;
    url: string;
    isCurrent?: boolean;
    description?: string;
  };

  const tools: Tool[] = [
    {
      name: "AskAlayna",
      url: "https://app.alayna.us",
      isCurrent: true,
      description: "AI assistant for personalized teaching support",
    },
    {
      name: "Slides Generator",
      url: "https://workspace.google.com/marketplace/app/alayna_ai_for_google_slides/503455951279",
      description: "Instantly create engaging classroom presentations",
    },
    {
      name: "MasteryMate",
      url: "https://app.masterymate.ai",
      description: "Create Project-Based Learning (PBL) experiences with AI",
    },
    {
      name: "GradingPal",
      url: "https://app.gradingpal.ai",
      description: "Save time grading assignments with AI",
    },
  ];

  return (
    <div className="bg-gray-100 text-sm relative">
      <div className="mx-auto px-4 relative">
        <div className="flex items-center transition-all duration-300 overflow-hidden h-10">
          <div className="flex-1 flex items-center space-x-4 h-full">
            <div className="flex items-center overflow-x-auto h-full">
              {tools.map((tool) => (
                <div
                  key={tool.name}
                  className={"flex-none h-full flex items-center relative"}
                >
                  {tool.isCurrent ? (
                    <div className="px-4 font-bold h-full flex items-center pt-1">
                      <span className="bg-[#0588f0] text-white rounded-t-xl flex h-full px-4 pt-2">
                        {tool.name}
                      </span>
                    </div>
                  ) : (
                    <Tooltip content={tool.description || ""}>
                      <a
                        href={tool.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 pt-1 text-gray-700 font-semibold no-underline transition-colors h-full flex items-center hover:text-gray-900 hover:bg-gray-200 group tool-highlight"
                        onClick={() => {
                          mixpanel.track("company_tools_banner_click", {
                            tool_name: tool.name,
                            tool_url: tool.url,
                          });
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <span>{tool.name}</span>
                        <ArrowRightIcon className="ml-1 h-3 w-3 opacity-0 group-hover:opacity-100 transition-opacity" />
                      </a>
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          </div>
          <a
            href="https://www.alayna.ai/products"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 transition-colors no-underline focus:outline-none font-medium h-full flex items-center px-2 text-gray-700 hover:bg-gray-200 rounded-sm"
            onClick={() => {
              mixpanel.track("company_tools_banner_click", {
                tool_name: "Learn More",
                tool_url: "https://www.alayna.ai/products",
              });
            }}
          >
            <InformationCircleIcon className="mr-1.5 h-3.5 w-3.5" />
            <span>Save 20 hours/week with Alayna AI Suite</span>
          </a>
        </div>
      </div>
    </div>
  );
};

function Dashboard() {
  const navigate = useNavigate();

  // const { addModal } = useModalContext();

  const { auth, drives } = useStores();

  const myDriveId = React.useMemo(() => {
    const myDrive = drives.sortedData.find((drive) => {
      return drive.driveType === EnumDriveDriveType.MyDrive;
    });

    if (!myDrive) return null;

    return myDrive.id;
  }, [drives.sortedData]);

  if (!auth.user) return null;

  // const isPaidPlan = auth.user.isSubscribedToPlan;

  // const freeTrialDaysLeft = auth.user.freeTrialDaysLeft;

  const currentPlan = auth.user.currentPlan;

  console.log("Current Plan", currentPlan);

  const recentActivities = toJS(auth.recentActivities);

  // Fetch recent activities on component mount
  React.useEffect(() => {
    auth.getRecentActivityItems();
  }, [auth]);

  // Set up link tracking on mount
  React.useEffect(() => {
    // Track main tool links
    mixpanel.track_links(".tool-link", "tool-clicked", (el: HTMLElement) => ({
      tool_name: el.getAttribute("data-tool-name"),
      tool_path: el.getAttribute("data-tool-path"),
    }));

    // Track resource links
    mixpanel.track_links(
      ".resource-link",
      "resource-clicked",
      (el: HTMLElement) => ({
        resource_name: el.getAttribute("data-resource-name"),
        resource_path: el.getAttribute("data-resource-path"),
      })
    );

    // Track social media links
    mixpanel.track_links(
      ".social-link",
      "social-media-clicked",
      (el: HTMLElement) => ({
        platform: el.getAttribute("data-platform"),
        url: el.getAttribute("href"),
      })
    );
  }, []);

  React.useEffect(() => {
    console.log("Recent Activities", recentActivities);
    // Track activity links
    mixpanel.track_links(
      ".activity-link",
      "activity-clicked",
      (el: HTMLElement) => ({
        activity_type: el.getAttribute("data-activity-type"),
        activity_title: el.getAttribute("data-activity-title"),
      })
    );
  }, [recentActivities]);

  // Helper function to get icon and color based on activity type
  const getActivityMeta = (type: string) => {
    switch (type) {
      case "Chat":
        return { icon: ChatIcon, color: "sky" };
      case "InstantResources":
        return { icon: AppsaddIcon, color: "green" };
      case "CurriculumCreator":
        return { icon: Book01Icon, color: "red" };
      case "ImageGenerator":
        return { icon: ImageIcon, color: "yellow" };
      case "Slides":
        return { icon: MonitorIcon, color: "orange" };
      case "Documents":
        return { icon: File03Icon, color: "red" };
      default:
        return { icon: File03Icon, color: "slate" };
    }
  };

  // Helper function to get friendly name for activity type
  const getActivityTypeName = (type: string) => {
    switch (type) {
      case "Chat":
        return "Chat with me";
      case "InstantResources":
        return "Differentiate It";
      case "CurriculumCreator":
        return "Design Curriculum";
      case "ImageGenerator":
        return "Create Images";
      case "Slides":
        return "Create Slides";
      case "Documents":
        return "Document";
      default:
        return "Resource";
    }
  };

  // Add this component for error state
  const ErrorMessage = () => (
    <div className="bg-red-50 border border-red-200 rounded-xl p-6 text-center">
      <p className="text-red-800">
        {auth.errorFetchingRecentActivities ||
          "Failed to load recent activities"}
      </p>
      <button
        onClick={() => auth.getRecentActivityItems()}
        className="mt-2 text-sm font-medium text-red-600 hover:text-red-800"
      >
        Try again
      </button>
    </div>
  );

  // Add this component for empty state
  const EmptyState = () => (
    <div className="bg-slate-50 border border-slate-200 rounded-xl p-8 text-center">
      <div className="w-16 h-16 bg-slate-100 rounded-full flex items-center justify-center mx-auto mb-4">
        <File03Icon className="w-8 h-8 text-slate-400" />
      </div>
      <h3 className="text-lg font-medium text-slate-900 mb-2">No drafts</h3>
      <p className="text-slate-600">
        Start using our AI tools to see your recent drafts here
      </p>
    </div>
  );

  // Add state to track current slide
  const [currentSlide, setCurrentSlide] = React.useState(0);

  // Custom arrow components
  const NextArrow = ({ onClick }: { onClick?: () => void }) => (
    <button
      onClick={onClick}
      className="absolute -right-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-md hover:bg-slate2 transition-colors"
      aria-label="Next slides"
    >
      <ChevronRightIcon className="w-5 h-5 text-slate11" />
    </button>
  );

  const PrevArrow = ({ onClick }: { onClick?: () => void }) => {
    // Don't render if we're at the beginning
    if (currentSlide === 0) return null;

    return (
      <button
        onClick={onClick}
        className="absolute -left-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-md hover:bg-slate2 transition-colors"
        aria-label="Previous slides"
      >
        <ChevronLeftIcon className="w-5 h-5 text-slate11" />
      </button>
    );
  };

  // Updated slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getActivityIconClasses = (color: string) => {
    return clsx(
      "shrink-0 w-10 h-10 rounded-xl flex items-center justify-center",
      {
        "bg-sky-50": color === "sky",
        "bg-green-50": color === "green",
        "bg-yellow-50": color === "yellow",
        "bg-red-50": color === "red",
        "bg-orange-50": color === "orange",
      }
    );
  };

  const getActivityIconColorClasses = (color: string) => {
    return clsx("w-5 h-5", {
      "text-sky-600": color === "sky",
      "text-green-600": color === "green",
      "text-yellow-600": color === "yellow",
      "text-red-600": color === "red",
      "text-orange-600": color === "orange",
    });
  };

  const getActivityTypeClasses = (color: string) => {
    return clsx("px-2 py-0.5 rounded-full", {
      "bg-sky-50 text-sky-700": color === "sky",
      "bg-green-50 text-green-700": color === "green",
      "bg-yellow-50 text-yellow-700": color === "yellow",
      "bg-red-50 text-red-700": color === "red",
      "bg-orange-50 text-orange-700": color === "orange",
    });
  };

  // Update the ActivityCard component
  const ActivityCard = ({ activity }: { activity: RecentActivityItem }) => {
    const { icon: ActivityIcon, color } = getActivityMeta(activity.type);
    const typeName = getActivityTypeName(activity.type);
    const { path } = getActivityUrl(activity.type, activity.url);

    return (
      <div className="p-2 relative">
        <Link
          to={path}
          data-activity-type={activity.type}
          data-activity-title={activity.title}
          className="activity-link block bg-white rounded-2xl p-6 shadow-sm hover:shadow-sm transition-all border border-slate-200 hover:border-slate-300 h-full"
        >
          <div className="flex gap-4">
            <div className={getActivityIconClasses(color)}>
              <ActivityIcon className={getActivityIconColorClasses(color)} />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-slate-900 mb-1 truncate">
                {activity.title}
              </h3>
              <div className="flex items-center gap-2 text-xs">
                <span className={getActivityTypeClasses(color)}>
                  {typeName}
                </span>
                <span className="text-slate10">
                  {formatDistanceToNow(new Date(activity.updatedAt), {
                    addSuffix: true,
                  })}
                </span>
              </div>
            </div>
          </div>
        </Link>
        <div className="absolute top-4 right-4">
          <ActivityDropdown
            activityId={activity.id}
            activityType={activity.type}
          />
        </div>
      </div>
    );
  };

  // Update the SkeletonCard component with a custom implementation
  const SkeletonCard = () => (
    <div className="p-2">
      <div className="block bg-white rounded-2xl p-6 shadow-sm border border-slate-200 h-full">
        <div className="flex gap-4">
          <div className="shrink-0 w-10 h-10 rounded-xl bg-slate-200 animate-pulse" />
          <div className="flex-1 min-w-0">
            <div className="h-5 w-3/4 mb-3 bg-slate-200 rounded animate-pulse" />
            <div className="flex items-center gap-2">
              <div className="h-4 w-20 bg-slate-200 rounded-full animate-pulse" />
              <div className="h-4 w-24 bg-slate-200 rounded-full animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#fafafa] max-w-[100vw] overflow-x-hidden">
      {/* Company Tools Banner */}
      <CompanyToolsBanner />
      {/* Header */}
      <div className="sticky top-0 left-0 right-0 bg-white border-b border-slate-200 z-50">
        <div className="h-18 px-6 md:px-8 flex items-center justify-between max-w-[1600px] mx-auto">
          <div className="flex items-center gap-3">
            <img
              src="/imgs/logos/askalayna.png"
              alt="Ask Alayna Logo"
              className="h-10"
            />
            {currentPlan !== PricingPlanTier.Basic && (
              <span className="px-2 py-0.5 text-xs font-medium bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-full">
                PRO
              </span>
            )}
          </div>

          <div className="flex items-center gap-4">
            {!!myDriveId && (
              <ButtonLarge
                buttonText="My Workspace"
                theme="secondary"
                type="button"
                rounded="full"
                onClick={() => navigate(driveRoute(myDriveId))}
              />
            )}
            <AccountDropdown>
              <button className="flex items-center gap-2 p-2 hover:bg-slate-100 rounded-full transition-all">
                {auth.user.avatarUrl ? (
                  <img
                    src={auth.user.avatarUrl}
                    alt="User Avatar"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white font-medium">
                    {auth.user?.firstName?.charAt(0)}
                    {auth.user?.lastName?.charAt(0)}
                  </div>
                )}
                <ChevronDownIcon className="w-4 h-4 text-slate-600" />
              </button>
            </AccountDropdown>
          </div>
        </div>
      </div>

      {/* <div className="absolute top-[4.5rem] left-0 right-0 w-full bg-lime-100 border-b border-lime-100">
        <div
          className={clsx(
            "flex items-center justify-center py-3 px-4 gap-4",
            "text-sm text-lime-700"
          )}
        >
          <span className="font-bold">
            Introducing GradingPal - AI-powered grading & feedback tool for
            teachers
          </span>
          <button
            onClick={() => window.open("https://gradingpal.ai", "_blank")}
            className="font-semibold underline hover:text-lime-800"
          >
            Learn More
          </button>
        </div>
      </div> */}

      {/* <Dialog.Root
        open={showFebruaryFlashBanner}
        onOpenChange={setShowGradingPalModal}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-blackA6 data-[state=open]:animate-overlayShow" />
          <Dialog.Content className="fixed left-1/2 top-1/2 max-h-[85vh] w-[90vw] max-w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-md bg-gray1 p-[25px] shadow-[var(--shadow-6)] focus:outline-none data-[state=open]:animate-contentShow bg-white">
            <div className="flex flex-col gap-4">
              <VisuallyHidden>
                <Dialog.Title>February 2025 = Free Slides Month</Dialog.Title>
                <Dialog.Description>
                  Get the Alayna AI PRO bundle for just $79/year (normally
                  $119). That's 33% less than the annual plan! Use code
                  FLASHFEBRUARY at checkout. Don't wait — the offer ends March
                  1st at 11:59 pm PST.
                </Dialog.Description>
              </VisuallyHidden>

              <img
                src="https://i.ibb.co/vCvBfTHj/February-Flash-Sale.png"
                alt="February Flash Sale"
                className="w-full rounded-lg"
              />

              <div className="bg-green-100 p-4 rounded-md flex flex-col">
                <span className="text-base font-bold text-center">
                  February Flash Sale
                </span>
                <span className="text-sm text-center mt-2">
                  Get the Alayna AI PRO bundle for just $79/year (normally
                  $119). That's 33% less than the annual plan! Use code{" "}
                  <strong>FLASHFEBRUARY</strong> at checkout. Don't wait — the
                  offer ends <strong>March 1st</strong> at 11:59 pm PST.
                </span>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  className="px-3 py-2 bg-gray-100 rounded-md hover:bg-gray-200"
                  onClick={() => setShowGradingPalModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root> */}

      {/* Main Content */}
      <div className="pt-16 px-6 md:px-8 max-w-[1600px] mx-auto">
        {/* Recent Activities Carousel */}
        {(auth.isFetchingRecentActivities ||
          auth.recentActivities.length > 0 ||
          auth.errorFetchingRecentActivities) && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-slate-900 mb-2">
              Continue where you left off
            </h2>
            <div className="relative pb-3">
              {auth.isFetchingRecentActivities ? (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </div>
              ) : auth.errorFetchingRecentActivities ? (
                <ErrorMessage />
              ) : auth.recentActivities.length === 0 ? (
                <EmptyState />
              ) : auth.recentActivities.length > 3 ? (
                <div id="activity-shortcuts">
                  <Slider {...sliderSettings}>
                    {auth.recentActivities.map((activity) => (
                      <ActivityCard key={activity.id} activity={activity} />
                    ))}
                  </Slider>
                </div>
              ) : (
                <div
                  className="grid grid-cols-1 md:grid-cols-3 gap-4"
                  id="activity-shortcuts"
                >
                  {auth.recentActivities.map((activity) => (
                    <ActivityCard key={activity.id} activity={activity} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-8 mb-12">
          {/* Tools Grid */}
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-bold text-slate-900">
              Pick your AI tool
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">
              {tools.map((tool) => (
                <Link
                  key={tool.title}
                  to={tool.path}
                  className="tool-link group block h-full"
                  data-tool-name={tool.title}
                  data-tool-path={tool.path}
                >
                  <div className="bg-white p-6 xl:p-8 rounded-2xl border border-slate-200 hover:border-slate-300 hover:shadow-sm transition-all h-full">
                    <div className="flex flex-col items-start gap-6 h-full">
                      <img
                        src={tool.logo}
                        alt={tool.title}
                        className="w-16 h-16 shrink-0"
                      />
                      <div className="flex flex-col min-h-0">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-3">
                          {tool.title}
                        </h2>
                        <p className="text-base text-slate-600">
                          {tool.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* Resources Column */}
          <div className="flex flex-col gap-4 w-full">
            <h2 className="text-2xl font-bold text-slate-900">Resources</h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-6">
              {resources.map((resource) => (
                <Link
                  key={resource.title}
                  to={resource.path}
                  target={resource.external ? "_blank" : undefined}
                  rel={resource.external ? "noopener noreferrer" : undefined}
                  className="resource-link group block h-full"
                  data-resource-name={resource.title}
                  data-resource-path={resource.path}
                >
                  <div className="w-full rounded-2xl bg-white border overflow-hidden border-slate-200 hover:border-slate-300 hover:shadow-sm transition-all h-full">
                    <div className="flex flex-col gap-2 h-full">
                      {resource.previewImage && (
                        <div className="max-h-[280px] rounded-t-lg overflow-hidden h-full">
                          <img
                            src={resource.previewImage}
                            alt={resource.title}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                      <div className="w-full flex flex-col justify-between p-6">
                        <div>
                          <h3 className="text-xl font-semibold text-slate-900 mb-2">
                            {resource.title}
                          </h3>
                          <p className="text-slate-600 mb-3">
                            {resource.description}
                          </p>
                        </div>
                        <span className="text-sm font-medium text-[#0588f0] hover:text-blue-700 flex items-center gap-1 self-start">
                          {resource.action}
                          <span className="transition-transform group-hover:animate-arrow-right">
                            →
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Upgrade Section */}
        {/* {!auth.user.enterpriseUser && !isPaidPlan && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-slate-900 mb-6">
              Your AskAlayna Plan
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="rounded-2xl bg-white border border-slate-200 p-8">
                {freeTrialDaysLeft > 0 ? (
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-blue-900 flex items-center gap-2">
                      <span>⏳</span> Free Trial for Pro
                    </h3>
                    <p className="text-blue-800">
                      {`${freeTrialDaysLeft} days until you're downgraded to Free plan.`}
                    </p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-blue-900 flex items-center gap-2">
                      <span>⭐</span> Upgrade to Pro
                    </h3>
                    <p className="text-blue-800">
                      Unlock the full potential of Alayna with our Pro plan.
                    </p>
                  </div>
                )}
                <Button
                  type="button"
                  theme="primary"
                  buttonText="Upgrade Now"
                  width="250"
                  onClick={() => {
                    addModal({
                      content: (
                        <div className="relative overflow-y-scroll h-full px-8">
                          <Pricing />
                        </div>
                      ),
                      size: "lg",
                    });
                  }}
                  rounded="medium"
                  className="mt-6"
                />
              </div>

              <div className="rounded-2xl bg-white border border-slate-200 p-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-pink-900 flex items-center gap-2">
                    <span>🩷</span> Refer teachers, earn free upgrades
                  </h3>
                  <p className="text-pink-800">
                    For every teacher you refer, you get a free upgrade to your
                    plan.
                  </p>
                  <Button
                    type="button"
                    theme="secondary"
                    width="250"
                    buttonText="Start Referring"
                    onClick={() => {
                      mixpanel.track("referral-program-clicked");
                      addModal({
                        content: <ReferralProgramModal />,
                        size: "md",
                      });
                    }}
                    rounded="medium"
                  />
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/* Other Tools */}
        {/* <div className="my-12">
          <h2 className="text-2xl font-bold text-slate-900 mb-6">
            Other Tools by Alayna AI
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {otherTools.map((tool) => (
              <div
                key={tool.title}
                className="bg-white rounded-2xl p-4 border border-slate-200 hover:border-slate-300 transition-all"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex-1 flex flex-col gap-3">
                    <div className="flex items-center gap-3 mb-1.5">
                      <img
                        src={tool.logo}
                        alt={tool.title}
                        className="w-10 h-10"
                      />
                      <div>
                        <h3 className="text-xl font-semibold text-slate-900">
                          {tool.title}
                        </h3>
                        <p className="text-sm text-slate-600">
                          {tool.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src={tool.featureImg}
                        alt={`${tool.title} Preview`}
                        className="w-full h-auto rounded-lg border border-slate-200"
                      />
                    </div>
                    <Button
                      type="button"
                      theme="secondary"
                      buttonText={
                        tool.title === "Alayna AI for Google Slides"
                          ? "Start for free"
                          : "Sign up 100% free"
                      }
                      onClick={() => {
                        mixpanel.track("other-tool-clicked", {
                          tool_name: tool.title,
                          destination: tool.externalLink || tool.path,
                          is_external: !!tool.externalLink,
                        });
                        if (tool.externalLink) {
                          window.open(tool.externalLink, "_blank");
                        } else if (tool.path) {
                          navigate(tool.path);
                        }
                      }}
                      width="full"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>

      {/* Footer */}
      <footer className="bg-white border-t border-slate-200 py-16">
        <div className="max-w-[1600px] mx-auto px-6 md:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Brand Column */}
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <img
                  src="/imgs/logos/askalayna.png"
                  alt="Ask Alayna Logo"
                  className="h-12"
                />
              </div>
              <p className="text-slate-600 text-sm">
                Simple, yet powerful AI teaching assistant for educators
              </p>
            </div>

            {/* Company Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Company</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/about"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    About
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://alayna.ai/careers"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Careers
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="https://alayna.ai/blog"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://calendly.com/hrishi-vora/alayna-intro"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Resources</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/use-cases"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Use Cases
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://alayna.ai/pricing"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.canva.com/design/DAF5XKzIa_o/PLPm0juXs0bBBT_LQCBSSQ/edit?utm_content=DAF5XKzIa_o&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    PD Deck
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://alayna.ai/teacher-advisors"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Teacher Advisors
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="https://alayna.featurebase.app/"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Feedback Forum
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/terms"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://alayna.ai/privacy"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://workspace.google.com/marketplace/app/alayna_ai_for_google_slides/503455951279"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Install Slides Extension
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/data-privacy"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Data Privacy
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 border-t border-slate-200 flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-slate-600 text-sm">
              © {new Date().getFullYear()} Cues Technologies Inc. All rights
              reserved.
            </p>
            <div className="flex items-center gap-4">
              <a
                href="https://www.facebook.com/profile.php?id=61564964300303"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link text-slate-400 hover:text-blue-600 transition-colors"
                data-platform="facebook"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://x.com/joinalayna"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link text-slate-400 hover:text-blue-600 transition-colors"
                data-platform="twitter"
                aria-label="X (formerly Twitter)"
              >
                <XIcon />
              </a>
              <a
                href="https://www.instagram.com/joinalayna/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link text-slate-400 hover:text-blue-600 transition-colors"
                data-platform="instagram"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.tiktok.com/@joinalayna"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link text-slate-400 hover:text-blue-600 transition-colors"
                data-platform="tiktok"
                aria-label="TikTok"
              >
                <TikTokIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/alayna"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link text-slate-400 hover:text-blue-600 transition-colors"
                data-platform="linkedin"
                aria-label="LinkedIn"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default observer(Dashboard);
